
body {
    font-family: 'Montserrat', sans-serif;
    background-color: #ECF1FB;
    font-weight: 400;
}

h1 {
    color: #f18965;
    font-size: 38px;
    line-height: 44px;
    font-weight: 600;
}
button {
    padding: 12px 20px;
    border-radius: 20px;
    background-color: #637B8F;
    box-shadow: 0 2px 8px 0 rgba(156, 156, 156, 0.1);
    transition: box-shadow 350ms ease, background-color 350ms ease, -webkit-transform 350ms ease;
    transition: transform 350ms ease, box-shadow 350ms ease, background-color 350ms ease;
    transition: transform 350ms ease, box-shadow 350ms ease, background-color 350ms ease, -webkit-transform 350ms ease;
    color: #fff;
    line-height: 1em;
    font-weight: 300;
    text-align: center;
    font-family: "Plus Jakarta Display", sans-serif;
    border-width: 0px;
}

.container {
    max-width: 700px;
    margin: auto;
    padding: 10px;
}

.hoverable{
    cursor: pointer;
}